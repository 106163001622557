import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m)

        this.events = {
            click: 'toggle'
        }
    }

    toggle() {
        if (html.classList.contains('has-modal-site-select-open')) {
            this.call('close', null, 'ModalSiteSelect')
        } else {
            this.call('open', null, 'ModalSiteSelect')
        }
    }
}
