import { default as Modal } from './AbstractModal'

export default class extends Modal {
    /**
     * The CSS class name to apply to `<html>` to mark the modal as open.
     *
     * @var {string}
     */
    activeClass = 'has-modal-menu-open'

    constructor(m) {
        super(m)

        this.$togglerWrapper = document.querySelector('.c-header')
        this.$accordions = this.el.querySelectorAll('.menu-item-has-children')
        
        // Binding
        this.onAccordionTogglerClinkBind = this.onAccordionTogglerClink.bind(this)
    }

    //////////////////////////
    // AbstractModal callbacks
    //////////////////////////
    onBeforeInit() {
        this.$accordions.forEach($accordion => {
            const $accordionContent = $accordion.children[1]
            $accordionContent.style.height = 'auto'
            const height = $accordionContent.clientHeight
            $accordionContent.style.height = '0px'

            $accordionContent.style.removeProperty('height')
            $accordion.style.setProperty('--content-height', `${height}px`)
        })
    }

    onClose() {
        this.closeAllAccordions()
        this.call('start', null, 'Scroll')
        this.unbindEvents()
    }

    onOpen(args) {
        this.call('stop', null, 'Scroll')
        this.bindEvents()
    }

    ///////////////
    // Events
    ///////////////
    bindEvents() {
        this.$accordions.forEach($accordion => {
            const $accordionToggler = $accordion.children[0]
            $accordionToggler.addEventListener('click', this.onAccordionTogglerClinkBind)
        })
    }

    unbindEvents() {
        this.$accordions.forEach($accordion => {
            const $accordionToggler = $accordion.children[0]
            $accordionToggler.removeEventListener('click', this.onAccordionTogglerClinkBind)
        })
    }
    
    ///////////////
    // Callbacks
    ///////////////
    onAccordionTogglerClink(e) {
        e.preventDefault()
        e.stopPropagation()
        
        const $target = e.target
        const $accordion = $target.parentNode

        this.toggleAccordion($accordion)
    }

    ///////////////
    // Methods
    ///////////////
    toggleAccordion($el) {
        $el.classList.contains('is-active') ? this.closeAccordion($el) : this.openAccordion($el)
    }

    openAccordion($el) {
        $el.classList.add('is-active')
    }

    closeAccordion($el) {
        $el.classList.remove('is-active')
    }

    closeAllAccordions() {
        this.$accordions.forEach($el => {
            this.closeAccordion($el)
        })
    }
}