import { default as Modal } from './AbstractModal'

export default class extends Modal {
    /**
     * The CSS class name to apply to `<html>` to mark the modal as open.
     *
     * @var {string}
     */
    activeClass = 'has-modal-site-select-open'

    constructor(m) {
        super(m)
    }

    ///////////////
    // Abstract Modal callbacks
    ///////////////
    onOpen(args) {
        this.call('close', null, 'ModalMenu')
        this.call('stop', null, 'Scroll')
    }

    onClose() {
        this.call('start', null, 'Scroll')
    }
}