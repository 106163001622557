import { module } from 'modujs';
import modularLoad from 'modularload';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        this.load = new modularLoad({
            enterDelay: 600
        });

        this.load.on('loading', (transition, oldContainer) => {
            this.call('close', null, 'ModalSiteSelect');
        })

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        })

        this.load.on('ready', (transition) => {
        })
    }
}
