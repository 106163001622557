import { module } from 'modujs'
import { Swiper } from 'swiper'

export default class extends module {
    constructor(m) {
        super(m)
    }

    init() {
        this.$container = this.$('container')[0]
        this.gutter = getComputedStyle(document.documentElement).getPropertyValue('--grid-gutter')
        this.gutter = parseInt(this.gutter.replace('px', ''))

        const args = {
            loop: true,
            spaceBetween: this.gutter,
            grabCursor: true,
            autoHeight: true,
            slidesPerView: 1,
            initialSlide: 2,
            breakpoints: {
                700: {
                    slidesPerView: 2
                    
                },
                1000: {
                    slidesPerView: 3
                }
            }
        }
        
        this.carousel = new Swiper(this.$container, args)
    }

    destroy() {
        super.destroy()
        this.carousel?.destroy(true, true)
    }
}
