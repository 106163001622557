////////////////
// Imports
////////////////
import modular from 'modujs'

import globals from './globals'
import * as modules from './modules'

import { html } from './utils/environment'
import config from './config'
import { isFontLoadingAPIAvailable, loadFonts } from './utils/fonts'
import debounce from "./utils/debounce"

// Fonts
export const EAGER_FONTS = [
    { family: 'HelveticaNowDisplay', style: 'normal', weight: 400 },
    { family: 'HelveticaNowDisplay', style: 'italic', weight: 400 }
]

// Modules
const app = new modular({
    modules: modules,
})


////////////////
// Init
////////////////
function init() {
    // Bind global events
    bindEvents()

    // First resize
    onResize()

    // Set initial viewport height
    document.documentElement.style.setProperty(
        "--vh-initial",
        `${window.innerHeight * 0.01}px`
    )

    // Globals
    globals()

    // Init modules
    app.init(app)

    // States classes
    setTimeout(() => {
        html.classList.add('is-loaded');
        html.classList.add('is-first-loaded');
        html.classList.remove('is-loading');

        setTimeout(() => {
            html.classList.add('is-ready');
        }, 400); 

    }, 400);

    // Debug focus
    // document.addEventListener(
    //     "focusin",
    //     function () {
    //         console.log('focused: ', document.activeElement)
    //     }, true
    // );

    /**
     * Eagerly load the following fonts.
     */
    if (isFontLoadingAPIAvailable) {
        loadFonts(EAGER_FONTS, config.IS_DEV).then((eagerFonts) => {
            html.classList.add('fonts-loaded')

            if (config.IS_DEV) {
                console.group('Eager fonts loaded!', eagerFonts.length, '/', document.fonts.size)
                console.group('State of eager fonts:')
                eagerFonts.forEach((font) => console.log(font.family, font.style, font.weight, font.status/*, font*/))
                console.groupEnd()
                console.group('State of all fonts:')
                document.fonts.forEach((font) => console.log(font.family, font.style, font.weight, font.status/*, font*/))
                console.groupEnd()
            }
        })
    }
}

////////////////
// Global events
////////////////
function bindEvents() {
    
    // Resize event
    const resizeEndEvent = new CustomEvent("resizeEnd")
    window.addEventListener(
        "resize",
        debounce(() => {
            window.dispatchEvent(resizeEndEvent)
        }, 200, false)
    )
    window.addEventListener(
        "resize",
        onResize
    ) 

    // Orientation change event
    window.addEventListener(
        "orientationchange",
        debounce(() => {
            onOnrientationChange()
        }, 200, false)
    )
}

function onOnrientationChange() {
    document.documentElement.style.setProperty(
        "--vh-initial",
        `${window.innerHeight * 0.01}px`
    )
}

function onResize() {
    let vw = html.offsetWidth * 0.01
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vw", `${vw}px`)
    document.documentElement.style.setProperty("--vh", `${vh}px`)
}

////////////////
// Execute
////////////////
window.onload = (event) => {
    const $style = document.getElementById('main-css')

    if ($style) {
        if ($style.isLoaded) {
            init()
        } else {
            $style.addEventListener('load', (event) => {
                init()
            })
        }
    } else {
        console.warn('The "main-css" stylesheet not found')
    }
}