import { module as Module } from "modujs"
import { whenReady } from "../utils/fonts"
import Raf from "quark-raf"
import { EAGER_FONTS } from "../app"

function isTouchDevice() {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    )
}

export default class extends Module {
    constructor(m) {
        super(m)

        // UI
        this.$image      = this.$('image')[0]
        this.$background = this.$('background')[0]
        this.$items      = this.$('item')

        // Binding
        this.onMouseEnterBind           = this.onMouseEnter.bind(this)
        this.onMouseLeaveBind           = this.onMouseLeave.bind(this)
        this.onMouseMoveBind            = this.onMouseMove.bind(this)
        this.onItemMouseEnterBind       = this.onItemMouseEnter.bind(this)
        this.onResizeBind               = this.onResize.bind(this)
        this.onFontsLoadedBind          = this.onFontsLoaded.bind(this)
        this.onTickBind                 = this.onTick.bind(this)

        this.mouseData = {
            // x: window.innerWidth * 0.5,
            y: window.innerHeight * 0.5,
            rawX: window.innerWidth * 0.5,
            rawY: window.innerHeight * 0.5,
            smoothX: window.innerWidth * 0.5,
            smoothY: window.innerHeight * 0.5,
            lerp: 0.15,
        }

        this.bounds = {
            // left: 0,
            top: 0,
            // width: 0,
            height: 0,
        }

        this.currentScroll = 0
    }

    //////////////////
    // Lifecycle
    //////////////////
    init() {
        if (isTouchDevice()) return
        this.bindEvents()
        whenReady(EAGER_FONTS).then(() => this.onFontsLoadedBind())
    }

    destroy() {
        this.stop()
        this.unbindEvents()
    }

    //////////////////
    // Events
    //////////////////
    bindEvents() {
        this.el.addEventListener("mouseenter", this.onMouseEnterBind)
        this.el.addEventListener("mouseleave", this.onMouseLeaveBind)
        this.el.addEventListener("mousemove", this.onMouseMoveBind)
        window.addEventListener("resize", this.onResizeBind)
        window.addEventListener("accordionOfficeOpen", this.onAccordionOfficeOpenBind)
        window.addEventListener("accordionOfficeClose", this.onAccordionOfficeCloseBind)

        this.$items.forEach(($item) => {
            $item.addEventListener('mouseenter', this.onItemMouseEnterBind)
        })
    }

    unbindEvents() {
        this.el.removeEventListener("mouseenter", this.onMouseEnterBind)
        this.el.removeEventListener("mouseleave", this.onMouseLeaveBind)
        this.el.removeEventListener("mousemove", this.onMouseMoveBind)
        window.removeEventListener("resize", this.onResizeBind)
        window.removeEventListener("accordionOfficeOpen", this.onAccordionOfficeOpenBind)
        window.removeEventListener("accordionOfficeClose", this.onAccordionOfficeCloseBind)

        this.$items.forEach(($item) => {
            $item.addEventListener('mouseenter', this.onItemMouseEnterBind)
        })
    }

    //////////////////
    // Events
    //////////////////
    onMouseEnter(e) {
        if (isTouchDevice() || window.innerWidth < 700) return

        // this.mouseData.rawX = e.clientX
        this.mouseData.rawY = e.clientY
        this.$image.classList.add("is-active")
    }

    onMouseLeave(e) {
        // this.mouseData.rawX = e.clientX
        this.mouseData.rawY = e.clientY
        this.$image.classList.remove("is-active")
    }

    onMouseMove(e) {
        if (isTouchDevice() || window.innerWidth < 700) return
        // this.mouseData.rawX = e.clientX
        this.mouseData.rawY = e.clientY
    }

    onResize() {
        this.setBounds()
    }

    onFontsLoaded() {
        this.setBounds()
    }

    onScroll(scrollData) {
        if (isTouchDevice() || window.innerWidth < 700) return
        
        const { scroll } = scrollData
        this.currentScroll = scroll
    }

    onTick() {
        if (isTouchDevice() || window.innerWidth < 700) return
        this.updateCursor(this.currentScroll)
    }

    onItemMouseEnter(e) {
        if (isTouchDevice() || window.innerWidth < 700) return

        const $target = e.target
        const src = this.getData('src', $target)

        if ($target.classList.contains('is-active') || src == null) {
            this.$image.classList.remove('is-active')
            return
        } else {
            this.$image.classList.add('is-active')
        }

        this.$background.style.backgroundImage = `url(${src})`
    }

    //////////////////
    // Methods
    //////////////////
    toggle(e) {
        if (isTouchDevice()) return

        const { way, target } = e
        if (target != this.el) return
        if (way === "enter") {
            this.start()
        } else {
            this.stop()
        }
    }

    start() {
        if (this.isPlaying) return
        this.isPlaying = true
        Raf.add(this.onTickBind)
    }

    stop() {
        if (!this.isPlaying) return
        this.isPlaying = false
        Raf.add(this.onTickBind)
    }

    setBounds() {
        this.rect = this.el.getBoundingClientRect()

        this.bounds = {
            // left: this.rect.left,
            top: this.rect.top + window.scrollY,
            // width: this.rect.width,
            height: this.rect.height,
        }
    }

    updateCursor(scroll) {
        // this.mouseData.x = this.mouseData.rawX - this.bounds.left
        this.mouseData.y = this.mouseData.rawY - (this.bounds.top - scroll)

        // this.mouseData.smoothX +=
        //     (this.mouseData.x - this.mouseData.smoothX) * this.mouseData.lerp
        // this.mouseData.smoothX =
        //     ((100 * (this.mouseData.smoothX + 0.01)) | 0) / 100

        this.mouseData.smoothY +=
            (this.mouseData.y - this.mouseData.smoothY) * this.mouseData.lerp
        this.mouseData.smoothY =
            ((100 * (this.mouseData.smoothY + 0.01)) | 0) / 100

        this.$image.style.transform = `translate3d(0, -50%, 0) translate3d(0px, ${this.mouseData.smoothY}px, 0)`
    }
}